import getIqResult from '../getIqResult.js';


function selectRandomElements(arrayOfArrays) {
   return arrayOfArrays.map(innerArray => {
    
     if (!innerArray.length) {
       throw new Error('Jedna z tabel jest pusta.');
     }
  
     const randomIndex = Math.floor(Math.random() * innerArray.length);
   
     return innerArray[randomIndex];
   });
 }


function getRandomElement(array) {
   if (!Array.isArray(array) || array.length === 0) {
     console.warn('Podana wartość musi być niepustą tablicą.');
     console.log("array?!", array)
     return undefined; // Lub inna wartość oznaczająca błąd, zależnie od kontekstu
   }
   const randomIndex = Math.floor(Math.random() * array.length);
   return array[randomIndex];
 }
 
 

const addOptionVariants = (chosenOptions) => {
    let wasTheSame = false;
    let wasTheSameCount = 0;

    for (let i = 0; i < chosenOptions.length - 1; i++) {
       const a = chosenOptions[i].split('.')[0];
       const b = chosenOptions[i + 1].split('.')[0];

       if (a === b) {
          if (wasTheSameCount > 1) {
             wasTheSameCount = -1;
          }

          if (!wasTheSame) {
             chosenOptions[i] += `.${wasTheSameCount}`;
             chosenOptions[i + 1] += `.${wasTheSameCount + 1}`;
          }

          if (wasTheSame) {
             chosenOptions[i + 1] += `.${wasTheSameCount + 1}`;
          }

          wasTheSame = true;
          wasTheSameCount++;
       } else {
          wasTheSameCount = 0;

          if (!wasTheSame && i === 0) {
             chosenOptions[i] += '.0';
             chosenOptions[i + 1] += '.0';
          }

          if (wasTheSame && i > 0) {
             chosenOptions[i + 1] += '.0';
          }

          if (!wasTheSame && i > 0) {
             chosenOptions[i + 1] += '.0';
          }

          wasTheSame = false;
       }
    }
    return chosenOptions;
 };

 
 function cleanSentences(sentencesArr) {
   return sentencesArr.map(sentence =>
     sentence.replace(/^ +/, '') // Usuwa spacje tylko na początku ciągu
   );
 }
 
 


 const showOpinion = (opinionId, alldata) => {
    let data = alldata.sections;
    let [chosenOption, chosenSection, chosenVariant] = opinionId.split('.');
    return data[chosenSection].options[chosenOption][chosenVariant];
 };

 const formatDate = (dateObject) => {
   const day = String(dateObject.getDate()).padStart(2, '0');
   const month = String(dateObject.getMonth() + 1).padStart(2, '0'); // Miesiące są liczone od 0 do 11
   const year = dateObject.getFullYear();
 
   return `${day}.${month}.${year}`;
 }


export const makeSentences = (chosenOptions, alldata, iqPrzeliczone, ids2DataIqOgolny, dataBadania) => {

   console.log(chosenOptions, alldata, iqPrzeliczone, ids2DataIqOgolny, dataBadania)


    const chosenOptionsWithVariants = addOptionVariants(chosenOptions);

    // let sentencesArr = chosenOptionsWithVariants.map(showOpinion);
    let sentencesArr = chosenOptionsWithVariants.map(opinionId => showOpinion(opinionId, alldata));

    // console.log('🟢', sentencesArr);

    if (iqPrzeliczone){
       // console.log('🟢', iqPrzeliczone.value);
       sentencesArr.unshift(getIqResult(iqPrzeliczone, ids2DataIqOgolny).replaceAll("{{dataBadania}}", formatDate(dataBadania)));
    } 

    let sentences = new Intl.ListFormat('en', {
       style: 'narrow',
       type: 'unit',
    }).format(sentencesArr);

    return sentences;
 };





const getSentencecByTitleAndPoints = (detailsRaw, chosenOptions, allData) => {
   detailsRaw.forEach(detail => {
       const option = chosenOptions.find(o => parseInt(o.split('.')[1], 10) === detail.id);
       if (option) {
           const points = parseInt(option.split('.')[0], 10);
         //   detail.rawValue = points;
           detail.value = points;
       }
   });


   const resultsArray = [];

   const sortedDetailsRaw = detailsRaw.sort((a, b) => b.value - a.value);

   sortedDetailsRaw.forEach(detail => {
       const options = allData.options.filter(option => option.title === detail.name);
       options.forEach(option => {
           const rangeStart = Math.min(...option.range);
           const rangeEnd = Math.max(...option.range);
           if (detail.value >= rangeStart && detail.value <= rangeEnd) {
               resultsArray.push(option.result);
           }
       });
   });


   return resultsArray;
}




const expressionsTeenager = ["{{chłopca|dziewczynki}}", "{{Chłopiec|Dziewczynka}}", "{{Dziecko}}"];
const expressionsChild = ["{{Nastolatek|Nastolatka}}", "{{nastolatka|nastolatki}}"];

function removeSentencesWithExpressions(resultsArray, expressions) {
   for (let i = 0; i < resultsArray.length; i++) {
       resultsArray[i] = resultsArray[i].filter(sentence => {
           return !expressions.some(expression => sentence.includes(expression));
       });
   }
   return resultsArray;
}


function getRandomSenteces(nestedArray) {
   const selectedElements = [];

   for (let i = 0; i < nestedArray.length; i++) {
       if (Array.isArray(nestedArray[i])) {
           const randomIndex = Math.floor(Math.random() * nestedArray[i].length);
           selectedElements.push(nestedArray[i][randomIndex]);
       } else {
           selectedElements.push(nestedArray[i]);
       }
   }

   return selectedElements;
}




 export const makeSentencesDynamic =  (chosenOptions, alldata, iqPrzeliczone, ids2DataIqOgolny, dataBadania, dataWiek, detailsRaw) => {
  
   let cleanedArray = []
   let sentencesArr =  getSentencecByTitleAndPoints(detailsRaw, chosenOptions, alldata)
   const isChild = dataWiek.wiek.years < 11;
   const isTeenager = dataWiek.wiek.years >= 11 && dataWiek.wiek.years <= 19;
   const isAdult = dataWiek.wiek.years >19;

   if(isTeenager){
      cleanedArray = removeSentencesWithExpressions(sentencesArr, expressionsTeenager)
   }
   if(isChild){
      cleanedArray = removeSentencesWithExpressions(sentencesArr, expressionsChild)
   }
   if(isAdult){
      cleanedArray = removeSentencesWithExpressions(sentencesArr, expressionsChild)
      cleanedArray = removeSentencesWithExpressions(sentencesArr, expressionsTeenager)
   }
   
   sentencesArr = getRandomSenteces(cleanedArray)
  

    if (iqPrzeliczone){
      //  console.log('🟢', iqPrzeliczone);
       sentencesArr.unshift(getIqResult(iqPrzeliczone, ids2DataIqOgolny).replaceAll("{{dataBadania}}", formatDate(dataBadania)));
    } 

    let sentences = new Intl.ListFormat('en', {
       style: 'narrow',
       type: 'unit',
    }).format(sentencesArr);

    return sentences;
 };


 export const IDSPmakeSentences = (detailsRaw, detailsRawIq, datasentences,  dataBadania) => {
   
   let sentencesArr = [];

   // IQ płynna
   if (detailsRawIq[0].value){
      let plynnaSentence = ''
      const arrayWithSentences = getIqResult(detailsRawIq[0].value, datasentences["iq płynna"].options)

      plynnaSentence = getRandomElement(arrayWithSentences) 
      const iqPlynna = `${datasentences["iq płynna"].sentence} ${plynnaSentence}` 
      sentencesArr.push(iqPlynna);
   }

   // IQ skrystalizowana
   if (detailsRawIq[1].value){
      let skrystalizowanaSentence = ''
      const arrayWithSentences = getIqResult(detailsRawIq[1].value, datasentences["iq skrystalizowana"].options)
      
      skrystalizowanaSentence = getRandomElement(arrayWithSentences) 
      const iqSkrystalizowana = `${skrystalizowanaSentence}` 
      sentencesArr.push(iqSkrystalizowana);
   }

   // IQ ogolna
   if (detailsRawIq[2].value){
      let ogolnaSentence = ''
      const arrayWithSentences = getIqResult(detailsRawIq[2].value, datasentences["iq ogólne"].options)
      
      ogolnaSentence = getRandomElement(arrayWithSentences) 
      const iqOgolne = `${ogolnaSentence}` 
      sentencesArr.push(iqOgolne);
   }



// IQ Płynna 1-7 
   for (let i = 1; i < 8; i++) {
      if (detailsRaw[i-1].value){
         // console.log(i, "IQ Płynna");

         let sentence = ''
         const arrayWithSentences = getIqResult(detailsRaw[i-1].value, datasentences[i].options)
         sentence = getRandomElement(arrayWithSentences) 
         sentencesArr.push(sentence);
      }
   }

// IQ skrystalizowane 12-15
   for (let i = 12; i < 16; i++) {
      if (detailsRaw[i-1].value){
         // console.log(i, "IQ skrystalizowane ");

         let sentence = ''
         const arrayWithSentences = getIqResult(detailsRaw[i-1].value, datasentences[i].options)
         sentence = getRandomElement(arrayWithSentences) 
         sentencesArr.push(sentence);
      }
   }

   // Umiejętności psychomotoryczne 8-10
   detailsRaw.slice(7, 10).some(item => item.value) && sentencesArr.push(`\n\nUmiejętności psychomotoryczne\n`);
   for (let i = 8; i < 11; i++) {
      if (detailsRaw[i-1].value){
         // console.log(i, "Umiejętności psychomotoryczne");

         let sentence = ''
         const arrayWithSentences = getIqResult(detailsRaw[i-1].value, datasentences[i].options)
         sentence = getRandomElement(arrayWithSentences) 
         sentencesArr.push(sentence);
      }
   }

   // Kompetencje społeczno-emocjonalne  11
   detailsRaw.slice(10, 11).some(item => item.value) && sentencesArr.push(`\n\nKompetencje społeczno-emocjonalne\n`);
   for (let i = 11; i < 12; i++) {
      if (detailsRaw[i-1].value){
         // console.log(i, "Kompetencje społeczno-emocjonalne");

         let sentence = ''
         const arrayWithSentences = getIqResult(detailsRaw[i-1].value, datasentences[i].options)
         sentence = getRandomElement(arrayWithSentences) 
         sentencesArr.push(sentence);
      }
   }


   // Matematyka 12
   detailsRaw.slice(11, 12).some(item => item.value) && sentencesArr.push(`\n\nMatematyka\n`);
   for (let i = 12; i < 13; i++) {
      if (detailsRaw[i-1].value){
         // console.log(i, "Matematyka");

         let sentence = ''
         const arrayWithSentences = getIqResult(detailsRaw[i-1].value, datasentences[i].options)
         sentence = getRandomElement(arrayWithSentences) 
         sentencesArr.push(sentence);
      }
   }


   // Język 13-15
   detailsRaw.slice(12, 15).some(item => item.value) && sentencesArr.push(`\n\nJęzyk\n`);
   for (let i = 13; i < 16; i++) {
      
      if (detailsRaw[i-1].value){
         // console.log(i, "Język");

         let sentence = ''
         const arrayWithSentences = getIqResult(detailsRaw[i-1].value, datasentences[i].options)
         sentence = getRandomElement(arrayWithSentences) 
         sentencesArr.push(sentence);
      }
   }

   // Motywacja 16-18
   detailsRaw.slice(15, 17).some(item => item.value) && sentencesArr.push(`\n\nMotywacja\n`);

   for (let i = 16; i <= 18; i++) {
      if (detailsRaw[i-1].value){
         // console.log(i,"Motywacja");

         let sentence = ''
         const arrayWithSentences = getIqResult(detailsRaw[i-1].value, datasentences[i].options)
         sentence = getRandomElement(arrayWithSentences) 
         sentencesArr.push(sentence);
      }
   }

   sentencesArr = cleanSentences(sentencesArr)


   let sentences = new Intl.ListFormat('en', {
      style: 'narrow',
      type: 'unit',
   }).format(sentencesArr);


   sentences = sentences.replaceAll("{{dataBadania}}", formatDate(dataBadania));

   return sentences;
};



function chooseRange(sentencesData, detailedSums) {

   // Convert detailedSums to an array and sort it by value in descending order
   const sortedDetailedSums = Object.entries(detailedSums)
   .sort((a, b) => b[1].iq - a[1].iq);


   // This will hold the final result arrays
   const results = [];
 
   // Iterate over each item in the sorted detailedSums
   for (const [sectionId, sectionData] of sortedDetailedSums) {

     // Find the corresponding section in sentencesData
     const section = sentencesData.sections[sectionId];

     
     // Check if the section exists
     if (section) {
       // Find the options range that the score falls into
       const option = section.options.find(option => 
         sectionData.iq >= option.range[0] && sectionData.iq <= option.range[1]
       );
 
       // If a matching range is found, add its result to the results array
       if (option) {
         const data = {range:option.range[0], sentences: option.result}
         results.push(data);
       }
     }
   }
 
   console.log("results:", results);
   return results;
 }





 function selectSentences(chosenSentences) {
   let lastIndexes = {}; 
   let selectedSentences = []; 

   chosenSentences.forEach(item => {
       let range = item.range;
       if (lastIndexes[range] === undefined) {
           lastIndexes[range] = -1;
       }

       let sentences = item.sentences;
       let index = (lastIndexes[range] + 1) % sentences.length;

       selectedSentences.push(sentences[index]); 
       lastIndexes[range] = index; 
   });

   return selectedSentences;
}



 export const makeSentencesFull = (detailedSums, sentencesData, iqPrzeliczone, ids2DataIqOgolny, dataBadania) => {

   const chosenSentences = chooseRange(sentencesData, detailedSums);
   const sentencesArr = selectSentences(chosenSentences);

   if (iqPrzeliczone){
      sentencesArr.unshift(getIqResult(iqPrzeliczone, ids2DataIqOgolny).replaceAll("{{dataBadania}}", formatDate(dataBadania)));
   } 

   let sentences = new Intl.ListFormat('en', {
      style: 'narrow',
      type: 'unit',
   }).format(sentencesArr);

   return sentences;
};



 