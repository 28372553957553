
/**
 * Zwraca opis wyniku testu IQ na podstawie podanej punktacji.
 * 
 * Przeszukuje podaną tablicę obiektów zawierających zakresy punktacji IQ oraz odpowiadające im opisy wyników.
 * Funkcja zwraca opis wyniku dla pierwszego zakresu punktacji, w którym podana punktacja się mieści.
 * Jeśli punktacja nie mieści się w żadnym z podanych zakresów, zwracany jest komunikat o niepoprawnym wyniku.
 * 
 * @param {number} score - Punktacja IQ do klasyfikacji.
 * @param {Array.<{range: number[], result: string}>} descriptonsWithRange - Tablica obiektów, gdzie każdy obiekt
 *        zawiera `range` (tablica dwuelementowa określająca minimalną i maksymalną wartość punktacji dla danego wyniku)
 *        oraz `result` (ciąg znaków opisujący wynik dla danego zakresu punktacji).
 * @returns {string} Opis wyniku testu IQ odpowiadający punktacji lub komunikat o niepoprawnym wyniku, jeśli
 *          punktacja nie mieści się w żadnym z zakresów.
 */
const getIqResult = (score, descriptonsWithRange) => {
    for(let i = 0; i < descriptonsWithRange.length; i++) {
        let range = descriptonsWithRange[i].range;
        if(score >= range[0] && score <= range[1]) {
            return descriptonsWithRange[i].result;
        }
    }
    return "Niepoprawny wynik IQ.";
}


export default getIqResult;